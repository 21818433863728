import App from './App.vue';
import axios from "@/plugins/axios";
import './event-bus/EventBus';
import VueDragDrop from './libs/vue-drag-drop/src/index.js';
import EventBus from "./event-bus/EventBus";
import {useSentry} from "./sentry";
import './styles/entry.js';
import {createApp} from 'vue'
import store from "./store";
import router from "@/routes";
import vuetify from "@/plugins/vuetify";
import Vue3GoogleOauth2 from "vue3-google-oauth2";

const app = createApp(App)
app.use(store);
app.use(router)
app.use(VueDragDrop);
app.use(vuetify)

const gAuthOptions = {
	clientId: '450887322890-ouc2ssk2gs9dkp5rei5qav9uj777436b.apps.googleusercontent.com',
	scope: 'profile email',
	prompt: 'select_account'
};
app.use(Vue3GoogleOauth2, gAuthOptions)


app.config.globalProperties.$http = axios;
app.config.globalProperties.$event = EventBus;
// app.config.globalProperties.$store = store;
useSentry(app);


app.mount('#app')
