import axios from "@/plugins/axios";
import {urls} from '@/config/urls';

/**
 * Service for handling Users module API requests.
 */
export default {
	search: function(text) {
		return axios.get(urls.SEARCH, {params: {query: text}});
	},
	loadNext: function(url) {
		return axios.get(url);
	}
};
