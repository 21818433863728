
import GroupApiService from "@/services/groups/GroupApiService";
/**
 * Vuex store getters for Groups module.
 */
export default {
	/**
	 * Gets all groups.
	 *
	 * @param state
	 * @returns {*|Getter<S, S>|Getter<S, R>}
	 */
	getGroups: function(state) {
		return state.groups.sort(function (a, b) {
			return a.name.localeCompare(b.name);
		});
	},

	/**
	 * Filters groups array to find group object by id.
	 * @param groupId
	 */
	getGroup: (state) => (groupId) => {
		return state.groups.find(function (item) {
				return item.id == groupId;
			});
	},

	/**
	 * Gets group by ID from API.
	 * Returns Promise.
	 */
	getGroupById: (state) => (id) => {
		return GroupApiService.getGroup(id);
	},

}
