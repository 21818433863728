import InboxApiService from "@/services/users/InboxApiService";

export default {
	namespaced: true,
	state: {
		currentOffset: 0,
		perPage: 10,
		inbox: [],
		total: 0,
		onlyUnread: true,
	},
	getters: {
		pages: (state) => {
			return Math.ceil(state.total / state.perPage);
		}
	},
	mutations: {
		REMOVE_NOTIFICATION(state, {notification}) {
			const index = state.inbox.map((notification) => notification.id).indexOf(notification.id);
			state.inbox.splice(index, 1);
		},
		ADD_NOTIFICATION(state, {notification}) {
			state.inbox.push(notification)
			state.currentOffset += 1
		},
		CLEAR_INBOX(state) {
			state.inbox.splice(0, state.inbox.length);
			state.inbox.total = 0;
		}
	},
	actions: {
		async getPage(context, {page}) {
			const pageContent = await InboxApiService.getMyInbox(context.state.onlyUnread, page);

		}
	},
};
