import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
	namespaced: true,
	state: {
		token: localStorage.getItem('authToken') || '',
		currentUser: null
	},
	getters: getters,
	actions: actions,
	mutations: mutations
};
