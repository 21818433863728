import axios from "@/plugins/axios";
import {urls} from '@/config/urls';

export default {
	getMyInbox: function (onlyUnread, offset = 0, limit = 10) {
		const url = onlyUnread ? urls.USERS_ME_INBOX_ONLY_UNREAD : urls.USERS_ME_INBOX;
		return axios.get(url, {params: {limit: limit, offset: offset}});
	},
	getMyInboxUnreadCount: function () {
		return axios.get(urls.USERS_ME_INBOX_UNREAD_COUNT);
	},
	getNotification: function (id) {
		return axios.get(urls.USERS_ME_NOTIFICATION + id + '/');
	},
	toggleNotification: function (id) {
		return axios.put(urls.USERS_ME_INBOX + id + '/', '');
	},
	readAllIssueNotifications: function (id) {
		return axios().put(urls.USERS_ME_INBOX + id + '/all', '');
	},
}
