<script>

import DirectoryTreeMenu from "@/components/issues/directory-tree/DirectoryTreeMenu.vue";
import UserRoleMixin from "@/mixins/UserRoleMixin";

export default {
	name: "DraggableDirectoryTree",
	components: {DirectoryTreeMenu},
	mixins: [UserRoleMixin],
	props: {
		items: {
			type: Array,
			required: true
		},
		item: {
			type: Object,
			default: null
		},
		parents: {
			type: Array,
			default: () => []
		},
		opened: {
			type: Array,
			default: null
		},
		draggable: {
			type: Boolean,
			default: true
		}
	},
	emits: ['update:opened'],
	data() {
		return {
			openedInner: [],
			isOver: false,
			menu: false,
		}
	},
	computed: {
		isOpen() {
			if (this.item === null) {
				return true;
			}
			return this.openedWrapper.indexOf(this.item.id) >= 0;
		},
		isRoot() {
			return this.item === null;
		},
		rootId() {
			if (this.item === null) {
				return null;
			}
			return this.item.id;
		},
		openedWrapper: {
			get() {
				if (this.opened === null) {
					return this.openedInner;
				}
				return this.opened;
			},
			set(value) {
				if (this.opened === null) {
					this.openedInner = value;
				} else {
					this.$emit('update:opened', value);
				}
			}
		},
		childParents() {
			if (this.item === null) {
				return [];
			}
			return [...this.parents, this.item.id];
		}
	},
	methods: {
		toggleOpen() {
			const item = this.item;
			let opened = [...this.openedWrapper];
			if (opened.indexOf(item.id) >= 0) {
				opened = opened.filter(id => id !== item.id)
			} else {
				opened.push(item.id)
			}
			this.openedWrapper = opened;
		},
		handleDragover(data, event) {
			if (
				'issue' === data.group
				&& this.rootId !== null
				&& this.rootId !== data.issue.directory
			) {
				this.isOver = true;
				return;
			}

			if (
				'directory' === data.group
				&& this.rootId !== data.id
				&& this.rootId !== data.parent
				&& this.parents.indexOf(data.id) < 0
			) {
				this.isOver = true;
				return;
			}

			event.dataTransfer.dropEffect = 'none';
			this.isOver = false;
		},
		handleDrop(data) {
			const vm = this;
			this.isOver = false;

			if (data.group !== 'directory' && data.group !== 'issue') {
				return;
			}

			if (data.group === 'directory') {
				const id = data.id;
				const directories = this.items
					.filter(item => item.id !== id);
				directories.push({
					id: id,
					parent: this.rootId
				});
				this.$store.dispatch('issues/moveDirectory', {directories: directories})
					.then(function () {
						vm.$emit('toggle', {
							id: vm.rootId,
							open: true,
						})
						vm.$event.$emit(vm.$event.types.MOVED_DIRECTORY, id);
					});
			} else {
				this.$store.dispatch('issues/addDirectorySubIssue', {
					directoryId: this.rootId,
					subIssue: data.issue
				})
			}
		},
		onDragleave() {
			this.isOver = false;
		}
	}
}
</script>

<template>
	<div>
		<drop
			v-if="item"
			@dragover="handleDragover"
			@drop="handleDrop"
			@dragleave="onDragleave"
		>
			<drag
				:draggable="draggable"
				class="drag"
				:transfer-data="{ group: 'directory', id: item.id, parent: item.parent}"
			>
				<template v-if="draggable" #image="{setImageRef}">
					<v-chip
						color="black"
						:ref="(ref) => setImageRef(ref)"
						class="ma-2"
						size="large"
					>
						<v-avatar left>
							<v-icon>mdi-folder</v-icon>
						</v-avatar>
						{{ item.name }}
					</v-chip>
				</template>
				<DirectoryTreeMenu v-if="item && leastRegular" v-model="menu" :directory="item">
					<template #activator="{props}">
						<v-list-item
							v-bind="props"
							:class="{'bg-yellow' : isOver}"
							:to="{name: 'issueDirectory', params: {id: item.id}}"
							@click.left="menu = false"
							@click.right.prevent="menu = true"
						>

							<template #prepend>
								<v-btn
									v-if="item.child_directories.length > 0"
									:icon="isOpen ? 'mdi-chevron-down' : 'mdi-chevron-right'"
									color="almostBlack"
									size="x-small"
									@click.prevent.stop="toggleOpen"
								/>
							</template>
							<v-list-item-title
								:class="{
							'ml-2': item.child_directories.length > 0,
							'ml-10': item.child_directories.length === 0
						}"
							>
								{{ item.name }}
							</v-list-item-title>

						</v-list-item>
					</template>
				</DirectoryTreeMenu>
			</drag>
		</drop>
		<drop
			v-else
			@dragover="handleDragover"
			@drop="handleDrop"
			@dragleave="onDragleave"
		>
			<v-list-item :class="{'bg-yellow' : isOver}">
				<template #prepend>
					<slot name="root-button">
						<v-avatar icon="mdi-folder" color="gray"></v-avatar>
					</slot>
				</template>
			</v-list-item>
		</drop>
		<v-list
			v-if="isOpen"
			:class="{'ml-4' : !isRoot}"
		>
			<DraggableDirectoryTree
				v-for="item in items"
				v-model:opened="openedWrapper"

				:key="item.id"
				:item="item"
				:items="item.child_directories"
				:parents="childParents"
			/>
		</v-list>
	</div>
</template>

<style scoped>

</style>
