import axios from "@/plugins/axios";
import {urls} from '@/config/urls';

/**
 * Service for handling Groups module API requests.
 */
export default {
	/**
	 * Gets groups list from API.
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getGroups: function () {
		return axios.get(urls.GROUPS);
	},

	/**
	 * Gets single group by given ID from API.
	 *
	 * @param groupId
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	getGroup: function (groupId) {
		return axios.get(urls.GROUPS + groupId + '/');
	},

	/**
	 * Updates group data to API.
	 *
	 * @param data
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	updateGroup: function (data) {
		return axios.patch(
			urls.GROUPS + data.id + '/',
			data,
		);
	},

	/**
	 * Creates new group in API.
	 *
	 * @param data
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	createGroup: function (data) {
		return axios.post(
			urls.GROUPS,
			data,
		);
	},

	/**
	 * Deletes group in API.
	 *
	 * @param data
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	deleteGroup: function (id) {
		return axios.delete(
			urls.GROUPS + id + '/',
		);
	},

	/**
	 * Searches groups by string.
	 *
	 * @param string
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	searchGroups: function (string) {
		return axios.get(
			urls.GROUPS_SEARCH,
			{
				params: {search: string}
			}
		);
	},

}
