<template>
    <component :is="element" class="logo">
        <span>MOT</span><span>ION</span>
		<span>TA</span><span>SK</span>
    </component>
</template>

<script>
export default {
    props: {
        element: {
            type: String,
            default: 'div'
        }
    }
}
</script>
