<template>
	<v-list-item two-line :to="to" style="min-width:240px;">
		<template #prepend>
			<slot name="avatar"></slot>
		</template>
		<v-list-item-title class="text-truncate">
			<slot name="name"></slot>
		</v-list-item-title>
		<v-list-item-subtitle class="d-flex align-center">
			<slot name="subline"></slot>
		</v-list-item-subtitle>
	</v-list-item>
</template>

<script>
export default {
	props: {
		to: {}
	}
};
</script>
