<template>
	<v-overlay :value="overlay">
		<v-progress-circular indeterminate size="64"></v-progress-circular>
	</v-overlay>
</template>

<script>
export default {
	name: "AppLoader",
	data: function () {
		return {
			overlay: false
		};
	},
	created() {
		const vm = this;
		this.$event.$on(this.$event.types.LOADING_APP, function () {
			vm.overlay = true;
		});
		this.$event.$on(this.$event.types.COMPLETE_LOADING_APP, function () {
			vm.overlay = false;
		})
	}
}
</script>

