export default {
	computed: {
		currentUser: function () {
			return this.$store.getters['login/currentUser'];
		},
		loggedUserId: function() {
			return this.currentUser ? this.currentUser.id : '';
		},
		isLogged: function () {
			return this.$store.getters['login/isLoggedIn'];
		},
		leastRegular() {
			return this.isAdmin || this.isRegular
		},
		isAdmin (){
			return this.currentUser && this.currentUser.permission === 'admin';
		},
		isRegular () {
			return this.currentUser && this.currentUser.permission === 'regular';
		},
		isExternal () {
			return this.currentUser && this.currentUser.permission === 'external';
		},
		isGoogle: function () {
			if(!this.isLogged) {
				return false;
			}
			const user = this.currentUser;
			if(!user) {
				return false;
			}
			return user.googleLogin || false;
		},
	},
	methods: {
		logout() {
			const vm = this;
			if (this.isGoogle) {
				this.$gAuth.signOut()
					.then(() => {
						vm.$store.dispatch('login/logout');
					})
					.catch(error => {
						console.log(error);
						vm.$event.$emit(vm.$event.types.NOTIFY_MESSAGES, [
							{
								type: 'error',
								title: 'Error',
								text: 'Could not be logged out from Google'
							}
						]);
					}).finally(() => {

				});
			} else {
				this.$store.dispatch('login/logout');
			}
		},
	}
}
