<template>
	<search-result-item :to="to">
		<template v-slot:avatar>
			<IssueAvatar :issue="issue" />
		</template>
		<template v-slot:name>{{ name }}</template>
		<template v-slot:subline>
			<span>{{ status }}</span>
			<v-chip v-if="directory" variant="outlined" color="black" class="ml-2">{{ folderName }}</v-chip>
		</template>
	</search-result-item>
</template>

<script>
import SearchResultItem from './SearchResultItem';
import IssueAvatar from "@/components/issues/IssueAvatar.vue";

export default {
	name: 'SearchResultItemIssue',
	components: {
		IssueAvatar,
		SearchResultItem
	},
	props: ['issue'],
	computed: {
		name: function () {
			return this.issue.name;
		},
		to: function () {
			if (!this.directory) {
				return undefined;
			}
			return {name: 'issueDirectoryIssue', params: {id: this.directory.id, issueId: this.issue.id}};
		},
		status: function () {
			if(!this.issue || !this.issue.status) {
				return '';
			}
			return this.firstUpperCase(this.issue.status);
		},
		directory: function () {
			if (!this.issue || !this.issue.issue_directory) {
				return null;
			}
			return this.issue.issue_directory;
		},
		folderName: function () {
			if (!this.directory) {
				return '';
			}
			return this.directory.name;
		}
	},
	methods: {
		firstUpperCase(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		}
	}
};
</script>

<style>
</style>
