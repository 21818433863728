<template>
	<v-app>
		<keep-alive>
			<slot></slot>
		</keep-alive>
	</v-app>
</template>

<script>
    export default {
        name: "NotLogged"
    }
</script>
