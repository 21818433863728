<template>
	<v-navigation-drawer v-model="show" :width="400" location="right" temporary>
		<v-list-item>
			<v-text-field
				v-model="searched"
				ref="searchInput"
				placeholder="Search"
				variant="underlined"
				color="primary"
				append-inner-icon="mdi-magnify"
				@update:model-value="search"
			></v-text-field>
		</v-list-item>
		<v-list-item v-if="loading">
			<v-progress-circular indeterminate></v-progress-circular>
		</v-list-item>
		<v-list-item v-if="isEmpty">
			No keywords Type keywords to start searching.
		</v-list-item>
		<v-list-item v-if="hasResults">
			<v-list>
				<search-result :result="issues" name="Issues">
					<template #default="{results}">
						<search-result-item-issue
							v-for="item in results"
							:key="item.id"
							:issue="item"
						/>
					</template>
				</search-result>
				<search-result :result="directories" name="Directories">
					<template #default="{results}">
						<search-result-item-directory
							v-for="item in results"
							:key="item.id"
							:directory="item"
						/>
					</template>
				</search-result>
			</v-list>
		</v-list-item>
	</v-navigation-drawer>
</template>

<script>
import _ from 'lodash';
import SearchResult from './SearchResult';
import SearchResultItemIssue from './SearchResultItemIssue';
import SearchResultItemDirectory from './SearchResultItemDirectory';

export default {
	name: 'AppSearch',
	components: {
		SearchResult,
		SearchResultItemIssue,
		SearchResultItemDirectory
	},
	data() {
		return {
			show: false,
			searched: '',
			loading: false,
		};
	},
	computed: {
		isEmpty: function () {
			return !this.searched && !this.loading;
		},
		issues: function () {
			return this.$store.getters['search/issues'];
		},
		directories: function () {
			return this.$store.getters['search/directories'];
		},
		hasResults: function () {
			return (this.issues.count > 0 || this.directories.count > 0) && !this.loading;
		},

	},
	created() {
		const vm = this;
		this.$event.$on(this.$event.types.SHOW_SEARCH, function () {
			vm.show = true;
			vm.$nextTick(() => {
				if(vm.$refs.searchInput) {
					vm.$refs.searchInput.focus();
				}
			});
		});
	},
	methods: {
		search: _.debounce(function (text) {
			if (text.length < 3) {
				return;
			}
			this.loading = true;
			const vm = this;
			this.$store.dispatch('search/search', {text: text}).finally(() => {
				vm.loading = false;
			});
		}, 400)
	}
};
</script>
