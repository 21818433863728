<template>
	<v-app>
		<component :is="layout">
			<router-view></router-view>
		</component>
		<AppLoader/>
		<AppDashboard/>
	</v-app>
</template>

<script lang="js">
import Default from "./layouts/Default";
import NotLogged from "./layouts/NotLogged";
import AppDashboard from '@/components/core/AppDashboard.vue';
import AppLoader from '@/components/core/AppLoader.vue';

export default {
	name: 'App',
	props: {
		source: String
	},
	components: {
		// LAYOUTS
		Default,
		NotLogged,
		// COMPONENTS
		AppDashboard,
		AppLoader,
	},
	computed: {
		layout() {
			if (this.$route.meta.layoutByAuthentication && !this.isAuthenticated) {
				return 'not-logged';
			}
			const layout = this.$route.meta.layout;
			if (!layout) {
				return 'default';
			}
			return layout;
		},
		isAuthenticated() {
			return this.$store.getters['login/isLoggedIn'];
		}
	},
	methods: {
		logout() {
			this.$router.push('/login')
		},
	},
	created() {
		const vm = this;

		this.$event.$on(this.$event.types.LOGOUT, this.logout);
		if (this.isAuthenticated && !this.$store.getters['login/currentUser']) {
			this.$store.dispatch('login/getMe');
		}

		// this.$http.interceptors.response.use(undefined, function (err) {
		// 	return new Promise(function () {
		// 		if (err.response.status === 401 && err.response.config && !err.response.config.__isRetryRequest) {
		// 			vm.$store.dispatch('login/logout', {automatic: true});
		// 		}
		// 		throw err;
		// 	});
		// });
	}
};
</script>
