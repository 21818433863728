import actions from './actions';
import getters from './getters';
import mutations from './mutations';

/**
 * Vuex store.
 * For more see: https://vuex.vuejs.org/guide/modules.html.
 */
const Issues = {
	namespaced: true,
	state: {
		issueDirectories: [],
		currentDirectory: null,
		selectedDirectories: [],
		directoryAuthorizedUsers: [],
		currentIssue: null,
		directoryCache: [],
		statuses: [
			{
				value: 'active',
				text: 'Active'
			},
			{
				value: 'completed',
				text: 'Completed'
			}
		]
	},
	mutations: mutations,
	actions: actions,
	getters: getters
};

export default Issues;
