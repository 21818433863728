
import Vuex from 'vuex';

import mutations from '@/store/mutations.js';
import StoreModuleUsers from '@/store/modules/users/store.js';
import StoreModuleGroups from '@/store/modules/groups/store.js';
import StoreModuleIssues from '@/store/modules/issues/store.js';
import StoreModuleSearch from '@/store/modules/search/store.js';
import StoreModuleLogin from '@/store/modules/login/store.js';
import StoreModuleInbox from '@/store/modules/inbox/index.js';

const store = new Vuex.Store({
	state: {},
	mutations,
	modules: {
		users: StoreModuleUsers,
		groups: StoreModuleGroups,
		issues: StoreModuleIssues,
		search: StoreModuleSearch,
		login: StoreModuleLogin,
		inbox: StoreModuleInbox
	},
});

export default store;
