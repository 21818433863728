<template>
	<v-app-bar class="header" color="almostBlack" absolute>
		<v-toolbar-title class="header__title">
			<AppLogo/>
		</v-toolbar-title>
		<v-badge :model-value="unreadCount > 0" color="primary">
			<template v-slot:badge>
				<span v-if="!loadingInbox">{{ unreadCount }}</span>
				<v-progress-circular indeterminate v-if="loadingInbox" :size="15"></v-progress-circular>
			</template>
			<v-btn
				:to="{name: 'inbox'}"
				variant="text"
				size="large"
				class="text-capitalize font-weight-light mx-1"
				@click="inboxReload"
			>
				Inbox
			</v-btn>
		</v-badge>
		<v-btn
			:to="{name: 'myTasks'}"
			variant="text"
			size="large"
			class="text-capitalize font-weight-light mx-1"
		>My Tasks
		</v-btn>
		<v-btn
			:to="{name: 'myWork'}"
			variant="text"
			size="large"
			class="text-capitalize font-weight-light mx-1"
		>My Work
		</v-btn>
		<div v-if="isAdmin">
			<v-btn
				:to="{name: 'usersList'}"
				variant="text"
				size="large"
				class="text-capitalize font-weight-light mx-1"
			>{{ 'Users' }}
			</v-btn>
			<v-btn
				:to="{name: 'groupsList'}"
				variant="text"
				size="large"
				class="text-capitalize font-weight-light mx-1"
			>{{ 'Groups' }}
			</v-btn>
		</div>
		<v-spacer></v-spacer>
		<v-btn icon="mdi-magnify" class="mr-2" @click="showSearch" />
		<v-btn variant="outlined" class="mr-2 text-lowercase" rounded="xl" append-icon="mdi-logout" @click="logout">
			<v-tooltip location="bottom" activator="parent" text="Logout"/>
			{{ userEmail }}
		</v-btn>
	</v-app-bar>
</template>

<script lang="js">
import AppLogo from '@/components/core/AppLogo.vue';
import LogoutButton from '@/components/core/LogoutButton.vue';
import UserRoleMixin from '@/mixins/UserRoleMixin.js';

export default {
	name: 'AppToolbar',
	components: {
		AppLogo,
		LogoutButton
	},
	mixins: [UserRoleMixin],
	data() {
		return {
			applicationName: process.env.VUE_APP_NAME + ' ' + process.env.APP_VERSION,
			drawer: null,
			inboxPooler: null,
			loadingInbox: false
		}
	},
	props: {
		menuItems: {
			type: Array,
			required: false,
			default: () => []
		},
	},
	computed: {
		userEmail: function () {
			return this.currentUser ? this.currentUser.email : '';
		},
		unreadCount: function () {
			return this.$store.getters['users/inboxUnreadCount']
		}
	},
	created() {
		const vm = this;
		this.init();
		this.$event.$on(this.$event.types.LOGIN, function () {
			vm.init();
		});
		this.$event.$on(this.$event.types.LOGOUT, function () {
			clearInterval(vm.inboxPooler)
		});
	},
	beforeDestroy() {
		clearInterval(this.inboxPooler)
	},
	methods: {
		init() {
			this.loadInbox();
			this.inboxPooler = setInterval(() => {
				this.loadInbox();
			}, 120000);
		},
		loadInbox() {
			const vm = this;
			if (this.loadingInbox || !this.isLogged) {
				return;
			}
			this.loadingInbox = true;
			this.$store.dispatch('users/inboxUnreadCount').finally(function () {
				vm.loadingInbox = false;
			})
		},
		showSearch() {
			this.$event.$emit(this.$event.types.SHOW_SEARCH);
		},
		inboxReload() {
			this.$event.$emit(this.$event.types.RELOAD_INBOX)
		},
	},
};
</script>
