<script>
import CreateDirectory from "@/components/issues/CreateDirectory.vue";
import DeleteDirectory from "@/components/issues/DeleteDirectory.vue";

export default {
	name: "DirectoryTreeMenu",
	components: {DeleteDirectory, CreateDirectory},
	props: {
		modelValue: {
			type: Boolean,
			default: false
		},
		activator: {
			type: String,
			default: ''
		},
		directory: {
			type: Object,
			default: null
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {
			createDirectoryDialog: false,
			deleteDirectoryDialog: false,
		};
	},
	computed: {
		attachElement() {
			return document.querySelector(this.activator);
		},
		menu: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit('update:modelValue', val);
			}
		}
	},
}
</script>

<template>
	<div v-if="directory">
		<v-menu v-model="menu" :target="attachElement">
			<v-list color="white">
				<v-list-item @click="createDirectoryDialog = true">
					<template #prepend>
						<v-icon size="20" color="grey darken-1">mdi-folder-plus</v-icon>
					</template>
					Create directory
				</v-list-item>
				<v-list-item @click="deleteDirectoryDialog = true">
					<template #prepend>
						<v-icon size="20" color="grey darken-1">mdi-folder-remove</v-icon>
					</template>
					Delete directory
				</v-list-item>
			</v-list>
		</v-menu>
		<CreateDirectory v-model="createDirectoryDialog" :directory="directory"/>
		<DeleteDirectory v-model="deleteDirectoryDialog" :directory="directory"/>
	</div>
</template>

<style scoped>

</style>
