import actions from './actions';
import getters from './getters';
import mutations from './mutations';

/**
 * Vuex store for Search module.
 * For more see: https://vuex.vuejs.org/guide/modules.html.
 */
const Search = {
	namespaced: true,
	state: {
		query: '',
		issues: {
			count : 0,
			results: []
		},
		directories: {
			count: 0,
			results: []
		},
		loadNext: null
	},
	mutations: mutations,
	actions: actions,
	getters: getters
};

export default Search;
