import emitter from 'tiny-emitter/instance'
import EVENT_TYPES from './eventTypes';
const EventBus = {
	$on: (...args) => emitter.on(...args),
	$once: (...args) => emitter.once(...args),
	$off: (...args) => emitter.off(...args),
	$emit: (...args) => emitter.emit(...args),
	types: EVENT_TYPES
};

export default EventBus;
