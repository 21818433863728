import {createRouter, createWebHistory} from 'vue-router';
import {routes} from './routes.js';

const router = createRouter({
	history: createWebHistory(),
	base: '/',
	routes
});

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (localStorage.getItem('authToken') === null) {
			next({
				path: 'login',
				//params: {nextUrl: to.fullPath}
			})
		}
	}
	next();
});

export default router;
