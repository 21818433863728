<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on: tooltip }">

			<v-icon
				right
				v-on="{ ...tooltip }"
				@click="logout"
			>mdi-logout
			</v-icon>
		</template>
		<span>Logout</span>
	</v-tooltip>
</template>

<script>
    export default {
        name: "LogoutButton",
        props: {
            googleLogin: {
                type: Boolean,
                default: false
            }
        },

    }
</script>

<style scoped>

</style>
