<script>

import DirectoryTreeMenu from "@/components/issues/directory-tree/DirectoryTreeMenu.vue";
import UserRoleMixin from "@/mixins/UserRoleMixin";

export default {
	name: "DraggableDirectoryTreeCustom",
	components: {DirectoryTreeMenu},
	mixins: [UserRoleMixin],
	props: {
		items: {
			type: Array,
			required: true
		},
		item: {
			type: Object,
			default: null
		},
		parents: {
			type: Array,
			default: () => []
		},
		opened: {
			type: Array,
			default: null
		},
		selected: {
			type: Array,
			default: null
		},
		draggable: {
			type: Boolean,
			default: true
		}
	},
	emits: ['update:opened', "update:selected"],
	data() {
		return {
			openedInner: [],
			selectedInner: [],
			isOver: false,
			menu: false,
			menuIdentifier: '',
			menuItem: null
		}
	},
	computed: {
		isOpen() {
			if (this.item === null) {
				return true;
			}
			return this.openedWrapper.indexOf(this.item.id) >= 0;
		},
		isSelected() {
			if (this.item === null) {
				return false;
			}
			return this.selectedWrapper.indexOf(this.item.id) >= 0;
		},
		subItems() {
			if (this.item === null) {
				return this.items;
			}
			return this.item.su;
		},
		itemIdentifier() {
			if (this.item === null) {
				return '';
			}
			return 'tree-item' + this.item.id;
		},
		hasChildren() {
			return this.subItems.length > 0;
		},
		hasMenu() {
			return this.item && this.leastRegular;
		},
		isRoot() {
			return this.item === null;
		},
		rootId() {
			if (this.item === null) {
				return null;
			}
			return this.item.id;
		},
		openedWrapper: {
			get() {
				if (this.opened === null) {
					return this.openedInner;
				}
				return this.opened;
			},
			set(value) {
				if (this.opened === null) {
					this.openedInner = value;
				} else {
					this.$emit('update:opened', value);
				}
			}
		},
		selectedWrapper: {
			get() {
				if (this.selected === null) {
					return this.selectedInner;
				}
				return this.selected;
			},
			set(value) {
				if (this.selected === null) {
					this.selectedInner = value;
				} else {
					this.$emit('update:selected', value);
				}
			}
		},
		childParents() {
			if (this.item === null) {
				return [];
			}
			return [...this.parents, this.item.id];
		}
	},
	methods: {
		toggleOpen() {
			const item = this.item;
			let opened = [...this.openedWrapper];
			if (opened.indexOf(item.id) >= 0) {
				opened = opened.filter(id => id !== item.id)
			} else {
				opened.push(item.id)
			}
			this.openedWrapper = opened;
		},
		handleDragover(data, event) {
			if (
				'issue' === data.group
				&& this.rootId !== null
				&& this.rootId !== data.issue.directory
			) {
				this.isOver = true;
				return;
			}

			if (
				'directory' === data.group
				&& this.rootId !== data.id
				&& this.rootId !== data.parent
				&& this.parents.indexOf(data.id) < 0
			) {
				this.isOver = true;
				return;
			}

			event.dataTransfer.dropEffect = 'none';
			this.isOver = false;
		},
		handleDrop(data) {
			const vm = this;
			this.isOver = false;

			if (data.group !== 'directory' && data.group !== 'issue') {
				return;
			}

			if (data.group === 'directory') {
				const id = data.id;
				const directories = this.items
					.filter(item => item.id !== id);
				directories.push({
					id: id,
					parent: this.rootId
				});
				this.$store.dispatch('issues/moveDirectory', {directories: directories})
					.then(function () {
						vm.$emit('toggle', {
							id: vm.rootId,
							open: true,
						})
						vm.$event.$emit(vm.$event.types.MOVED_DIRECTORY, id);
					});
			} else {
				this.$store.dispatch('issues/addDirectorySubIssue', {
					directoryId: this.rootId,
					subIssue: data.issue
				})
			}
		},
		openMenu() {
			this.$emit('openMenu', { item: this.item, identifier: this.itemIdentifier });
		},
		handleOpenMenu({item, identifier}) {
			if (!this.isRoot) {
				this.$emit('openMenu', { item, identifier });
			}
			this.menuItem = item;
			this.menuIdentifier = '#' + identifier;
			this.$nextTick(() => {
				this.menu = true;
			});
		},
		onDragleave() {
			this.isOver = false;
		}
	}
}
</script>

<template>
	<component :is="item ? 'li' : 'div'" class="draggableTree">
		<drop
			v-if="item"
			@dragover="handleDragover"
			@drop="handleDrop"
			@dragleave="onDragleave"
			:class="{'bg-yellow' : isOver, 'bg-primary' : isSelected}"
		>
			<drag
				tag="router-link"
				:draggable="draggable"
				class="drag draggableTree__item"
				:transfer-data="{ group: 'directory', id: item.id, parent: item.parent}"
				:id="itemIdentifier"
				@click.right.prevent.stop="openMenu"
				:to="{name: 'issueDirectory', params: {id: item.id}}"
			>
				<button
					v-if="item.child_directories.length > 0"
					@click.prevent.stop="toggleOpen"
					class="btn__open"
				>
					<v-icon>
						{{ isOpen ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
					</v-icon>
				</button>
				<span :class="{'ml-11': item.child_directories.length === 0 }">
					{{ item.name }}
				</span>
			</drag>
		</drop>
		<ul
			v-if="isOpen"
			:class="{'ml-4' : !isRoot}"
		>
			<drop
				v-if="isRoot"
				tag="li"
				@dragover="handleDragover"
				@drop="handleDrop"
				@dragleave="onDragleave"
				:class="{'bg-yellow' : isOver}"
			>
				<slot name="root-button">
					<v-avatar icon="mdi-folder" color="gray"></v-avatar>
				</slot>
			</drop>
			<DraggableDirectoryTreeCustom
				v-for="item in items"
				v-model:opened="openedWrapper"
				v-model:selected="selectedWrapper"
				:key="item.id"
				:item="item"
				:items="item.child_directories"
				:parents="childParents"
				@openMenu="handleOpenMenu"
			/>
		</ul>
		<DirectoryTreeMenu
			v-if="isRoot"
			v-model="menu"
			:activator="menuIdentifier"
			:directory="menuItem"
			/>
	</component>
</template>

<style type="scss" scoped>
.draggableTree ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.btn__open {
	border-radius: 50%;
	padding: 0.1rem;
	margin-left: 0.50rem;
	margin-right: 0.50rem;
	&:hover {
		background-color: rgba(120,160,175,1);
	}
}

.draggableTree__item {
	cursor: pointer;
	display: flex;
	align-items: center;
	padding:  0.5rem 0;
	color: white;
	text-decoration: none;
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
	& span {
		text-wrap: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

</style>
