<template>
	<UserAvatar :user="user" />
</template>

<script>
import UserAvatar from "@/components/core/UserAvatar.vue";

export default {
	name: "IssueAvatar",
	components: {UserAvatar},
	props: {
		size: {
			type: Number,
			default: 30
		},
		color: {
			type: String,
			default: "grey"
		},
		issue: {
			type: Object,
			required: true
		}
	},
	computed: {
		user: function () {
			if (!this.issue.solvers || this.issue.solvers.length === 0) {
				return null;
			}
			return this.issue.solvers[0];
		},
	}
}
</script>
