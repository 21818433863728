import axios from "@/plugins/axios";
import {urls} from '@/config/urls';

/**
 * Service for handling Issues module API requests.
 */
export default {
	/**
	 * Gets issue dirs from API.
	 *
	 * @returns {AxiosPromise<any>}
	 */
	getDirectories: function () {
		return axios.get(urls.ISSUES_DIRECTORY);
	},

	/**
	 * Gets  directory by ID.
	 *
	 * @returns {AxiosPromise<any>}
	 */
	getDirectory: function (id) {
		return axios.get( urls.ISSUES_DIRECTORY + id + '/');
	},

	/**
	 * Gets  directory by ID for editing => with more data.
	 *
	 * @returns {AxiosPromise<any>}
	 */
	getDirectoryEdit: function (id) {
		return axios.get(urls.ISSUES_DIRECTORY + id + '/edit/');
	},
	getAuthorizedDirectoryUsers: function (id) {
		return axios.get(urls.ISSUES_DIRECTORY + id + '/authorized-users/');
	},
	/**
	 * Gets issues list from API.
	 *
	 * @returns {AxiosPromise<any>}
	 */
	getIssueCategories: function () {
		return axios.get(urls.ISSUES_DIRECTORY);
	},

	/**
	 * Gets issue.
	 *
	 * @param id
	 * @returns {AxiosPromise<any>}
	 */
	getIssue: function (id) {
		return axios.get(urls.ISSUES + id + '/');
	},

	/**
	 * Creates new issue in API.
	 *
	 * @param data
	 * @returns {AxiosPromise<any>}
	 */
	createIssue: function (data) {
		return axios.post(
			urls.ISSUES,
			data
		);
	},

	/**
	 * Updates existing issue in API.
	 *
	 * @param data
	 * @returns {AxiosPromise<any>}
	 */
	updateIssue: function (data) {
		const version = data.version;
		const force = data.hasOwnProperty('force') && data.force === true;
		if (force) {
			delete data.force;
		}
		delete data.version;
		const requestData = {
			version: version,
			data: data
		};
		if(force)  {
			requestData.force_update = true;
		}
		return axios.patch(
			urls.ISSUES + data.id + '/',
			requestData
		);
	},

	/**
	 * Updates existing directory in API.
	 *
	 * @param data
	 * @returns {AxiosPromise<any>}
	 */
	updateDirectory: function (data) {
		return axios.patch(
			urls.ISSUES_DIRECTORY + data.id + '/edit/',
			data
		);
	},

	createDirectory: function (data) {
		return axios.post(
			urls.ISSUES_DIRECTORY,
			data
		);
	},

	deleteDirectory: function (id) {
		return axios.delete(
			urls.ISSUES_DIRECTORY + id + '/edit/'
		);
	},

	deleteIssue: function (id) {
		return axios.delete(
			urls.ISSUES + id + '/'
		);
	},

	uploadFile: function (file) {
		const data = new FormData();
		data.append('file', file);
		return axios.post(
			urls.ISSUES_FILES,
			data
		);
	},
	commentCurrentIssue: function (issueId, comment) {
		return axios.post(
			urls.ISSUES + issueId + '/comment/',
			comment
		);
	},
	updateComment(issueId, comment) {
		return axios.patch(
			urls.ISSUES + issueId + '/comment/' + comment.id + '/',
			comment
		);
	}
};
