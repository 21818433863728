import * as mt from '@/store/mutations-types.js';
export default {
	[mt.SET_CURRENT_USER]: (state, data) => {
		state.currentUser = data;
	},
	[mt.LOGIN_USER]: (state, data) => {
		state.token = data.token;
		state.currentUser = data.user;
	},
	[mt.CLEAN_TOKEN]: (state) => {
		state.token = '';
	},
}
