import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'

const lightTaskMotion = {
	dark: false,
	colors: {
		primary: '#1976D2',
		secondary: '#424242',
		accent: '#82B1FF',
		error: '#FF5252',
		info: '#2196F3',
		success: '#4CAF50',
		warning: '#FFC107',
		almostBlack: '#1D364C',
		background: '#1d364c',
		surface: '#ffffff'
	}
}

export default createVuetify({
	icons: {
		iconfont: 'mdi',
	},
	theme: {
		defaultTheme: 'lightTaskMotion',
		themes: {
			lightTaskMotion,
		},
	},
});
