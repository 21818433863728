<script>
import {v4} from "uuid";

export default {
	name: "AppNotifications",
	data() {
		return {
			messages: [],
			timeout: 10000
		}
	},
	methods: {
		getColor(type) {
			switch (type) {
				case 'success':
					return 'success';
				case 'error':
					return 'error';
				case 'warning':
					return 'warning';
				case 'info':
					return 'primary';
				default:
					return 'primary';
			}
		},
		/**
		 * Shows flash messages using vue-notifications.
		 */
		showMessages(messages) {
			messages.forEach(value => {
				const snack = {
					...value,
					color: this.getColor(value.type),
					uuid: v4(),
				}
				this.messages.push(snack);
				setTimeout(() => {
					this.removeMessage(snack.uuid);
				}, value?.timeout || this.timeout);
			});
		},
		removeMessage(uuid) {
			this.messages = this.messages.filter(item => item.uuid !== uuid);
		}
	},
	created() {
		this.$event.$on(this.$event.types.NOTIFY_MESSAGES, messages => this.showMessages(messages));
	},
}
</script>

<template>
<div class="notifications">
	<v-alert
		v-for="message in messages"
		class="ma-2 text-body-2"
		min-width="300px"
		:key="message.uuid"
		:type="message.type"
		closable
		@update:model-value="removeMessage(message.uuid)"
	>
		<span class="font-weight-bold">
			{{ message.title }}
		</span><br>
		{{ message.text }}
	</v-alert>
</div>
</template>

<style scoped>
.notifications {
	position: fixed;
	top: 3.5rem;
	right: 0;
	z-index: 9999;
}
</style>
