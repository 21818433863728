export default {
    currentUser: state => state.currentUser,
    hasTaskFilter: (state) => {
        if (!state.currentUser) {
            return false
        }
        return state.currentUser.has_task_filter;
    },
    isLoggedIn: state => !!state.token,
}
