import axios from 'axios';

import userDataStorage from "@/plugins/userDataStorage";

const client = axios.create({
	baseURL: `${window.location.protocol}//${window.location.host}/api/v1`,
	withCredentials: true,
});

client.interceptors.request.use((config) => {
	const token = userDataStorage.get("authToken");
	if (!token) {
		return config;
	}
	config.headers.Authorization = `Bearer ${token}`;
	return config;
});

export const contentTypeJson = {
	headers: {
		'Content-Type': 'application/json',
	},
};

export default client;
