
import axios from "@/plugins/axios";
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import {base} from './config/base';

const sentryDNS = 'https://23526f3240e34f3dae190cce50a16ad1@sentry.olc.cz/29';
let dns = false;

export const useSentry = (app) => {
	Sentry.init({
		app,
		dsn: dns,
		release: base.APP_SENTRY_RELEASE
	});

	console.log(base.APP_SENTRY_RELEASE);

	axios.interceptors.request.use(
		requestConfig => requestConfig,
		(requestError) => {
			Sentry.captureException(requestError);

			return Promise.reject(requestError);
		},
	);

// Add a response interceptor
	axios.interceptors.response.use(
		response => response,
		(error) => {
			if (error.response.status >= 500) {
				Sentry.captureException(error);
			}

			return Promise.reject(error);
		},
	);

}

