import IssueApiService from "../services/issues/IssueApiService";

export const routes = [
	{
		name: 'Home',
		path: '/',
		redirect: {name: 'inbox'},
		meta: {
			requiresAuth: true
		}
	},
	// Errors
	{
		name: '403',
		path: '/403',
		component: () => import('@/views/Error/403.vue'),
		meta: {
			layoutByAuthentication: true
		}
	},
	{
		name: '404',
		path: '/404',
		component: () => import('@/views/Error/404.vue'),
		meta: {
			layoutByAuthentication: true
		}
	},
	{
		name: '500',
		path: '/500',
		component: () => import('@/views/Error/500.vue'),
		meta: {
			layoutByAuthentication: true
		}
	},
	{
		name: 'Log In',
		path: '/login',
		component: () => import('@/views/LoginView.vue'),
		meta: {
			layout: 'not-logged'
		}
	},
	{
		name: 'Logout',
		path: '/logout',
		redirect: '/login'
	},
	// RESET PASSWORD
	{
		name: 'resetPassword',
		path: '/reset-password',
		component: () => import('@/views/ResetPasswordView.vue'),
		meta: {
			layout: 'not-logged'
		}
	},
	{
		name: 'newPassword',
		path: '/new-password/:token',
		component: () => import('@/views/NewPasswordView.vue'),
		meta: {
			layout: 'not-logged'
		}
	},
	// Issues
	{
		name: 'issueDirectory',
		path: '/issue-directory/:id([A-Za-z0-9]+)',
		component: () => import('@/views/Main/Issues.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		name: 'issueDirectoryIssue',
		path: '/issue-directory/:id([A-Za-z0-9]+)/issue/:issueId([A-Za-z0-9]+)',
		component: () => import('@/views/Main/Issues.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		name: 'shortIssueLink',
		path: '/task/:issueId([A-Za-z0-9]+)',
		beforeEnter: (to, from, next) => {
			IssueApiService.getIssue(to.params.issueId)
				.then(response => {
					next({
						name: 'issueDirectoryIssue',
						params: {
							issueId: to.params.issueId,
							id: response.data.issue_directory.id
						},
					})
				})
				.catch(error => {
					console.log(error);
					const status = error.response.status;
					if (status === 404 || status === 403 || status === 500) {
						next(status.toString());
					} else {
						next('500')
					}

				})
		},
		meta: {
			requiresAuth: true
		}
	},
	/*
	{
		name: 'issueFormNew',
		path: '/issue/new',
		component: () => import('@/views/Main/Issues.vue')
	},
	 */
	{
		name: 'issueDetail',
		path: '/issue/:id([A-Za-z0-9]+)',
		component: () => import('@/views/Main/Issues.vue'),
		meta: {
			requiresAuth: true
		}
	},

	// Inbox
	{
		name: 'inbox',
		path: '/inbox',
		component: () => import('@/views/Main/Inbox.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		name: 'inboxDetail',
		path: '/inbox/:notificationId([A-Za-z0-9]+)',
		component: () => import('@/views/Main/Inbox.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		name: 'myTasks',
		path: '/my-tasks',
		component: () => import('@/views/Main/MyTasks.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		name: 'myTasksDetail',
		path: '/my-tasks/:issueId([A-Za-z0-9]+)',
		component: () => import('@/views/Main/MyTasks.vue'),
		meta: {
			requiresAuth: true
		}
	},

	{
		name: 'myWork',
		path: '/my-work',
		component: () => import('@/views/Main/MyWork.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		name: 'myWorkDetail',
		path: '/my-work/:issueId([A-Za-z0-9]+)',
		component: () => import('@/views/Main/MyWork.vue'),
		meta: {
			requiresAuth: true
		}
	},

	// Users
	{
		name: 'usersList',
		path: '/users',
		component: () => import('@/views/Main/Users.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		name: 'userForm',
		path: '/user/:userId([A-Za-z0-9]+)',
		component: () => import('@/views/Main/Users.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		name: 'userFormRegister',
		path: '/register/:token',
		component: () => import('@/views/RegistrationView.vue'),
		meta: {
			layout: 'not-logged'
		}
	},

	// Groups
	{
		name: 'groupsList',
		path: '/groups',
		component: () => import('@/views/Main/Groups.vue')
	},
	{
		name: 'groupForm',
		path: '/group/:groupId([A-Za-z0-9]+)',
		component: () => import('@/views/Main/Groups.vue')
	},

	{
		path: '/:catchAll(.*)"',
		redirect: '/404'
	}
];
