<template>
	<v-list-item>
		<v-row>
			<v-col :sm="12">
				<v-list subheader>
					<div>{{name}} ({{ result.count}})</div>
					<slot :results="displayedResults"></slot>
				</v-list>
			</v-col>
			<v-col :sm="12">
				<v-btn v-if="hasNext" variant="text"  @click="next">Next</v-btn>
			</v-col>
		</v-row>
	</v-list-item>
</template>

<script>
export default {
	props: ['name', 'result'],
	data: function() {
		return {
			displayed: 10
		};
	},
	computed: {
		displayedResults: function() {
			return this.result.results.slice(0,this.diplayed);
		},
		hasNext: function() {
			return this.totalCount > this.displayed;
		},
		totalCount: function() {
			return this.result.count;
		}
	},
	methods: {
		reset: function() {
			this.displayed = 10;
		},
		next: function() {
			if(this.result.results.length <= this.displayed && this.displayed < this.totalCount) {
				this.$store.dispatch('search/loadNext');
			}
			this.displayed += 10;
		}
	}
};
</script>
