// Users
export const SET_USERS = 'SET_USERS';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_LOADER = 'ADD_USER_LOADER';
export const SET_LOADING_INBOX = 'SET_LOADING_INBOX';
export const SET_INBOX = 'SET_INBOX';
export const APPEND_INBOX = 'APPEND_INBOX';
export const PREPEND_INBOX = 'PREPEND_INBOX';
export const RESET_INBOX = 'RESET_INBOX';
export const SET_INBOX_UNREAD_COUNT = 'SET_INBOX_UNREAD_COUNT';
export const SET_INBOX_UNREAD_COUNT_INITED = 'SET_INBOX_UNREAD_COUNT_INITED';
export const READ_ALL_INBOX = "READ_ALL_INBOX";
export const SET_MY_TASKS = 'SET_MY_TASKS';
export const SET_MY_WORK = 'SET_MY_WORK';
export const SET_INBOX_ONLY_UNREAD = 'SET_INBOX_ONLY_UNREAD';
export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';

// Groups
export const SET_GROUPS = 'SET_GROUPS';

// Issues
export const SET_DIRECTORIES = 'SET_DIRECTORIES';
export const SET_CURRENT_DIRECTORY = 'SET_CURRENT_DIRECTORY';
export const SET_DIRECTORY_AUTHORIZED_USERS = 'SET_CURRENT_DIRECTORY_AUTHORIZED_USERS';
export const SET_CURRENT_ISSUE = 'SET_CURRENT_ISSUE';
export const RESET_CURRENT_ISSUE = 'RESET_CURRENT_ISSUE';
export const ADD_WATCHER = 'ADD_WATCHER';
export const REMOVE_WATCHER = 'REMOVE_WATCHER';
export const ADD_SOLVER = 'ADD_SOLVER';
export const REMOVE_SOLVER = 'REMOVE_SOLVER';
export const ADD_DIRECTORY_CACHE =  'ADD_DIRECTORY_CACHE';
export const SET_SELECTED_DIRECTORIES = 'SET_SELECTED_DIRECTORIES';

// Search
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const INIT_SEARCH = 'INIT_SEARCH';
export const NEXT_SEARCH = 'NEXT_SEARCH';

// login
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const CLEAN_TOKEN = 'CLEAN_TOKEN';
