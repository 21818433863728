<template>
	<v-sheet flat color="almostBlack">
		<v-overlay :model-value="loading" contained class="align-center justify-center">
			<v-progress-circular
				indeterminate
				color="primary"
			></v-progress-circular>
		</v-overlay>
		<CreateDirectory v-model="createRootDirectory"/>
		<DraggableDirectoryTreeCustom
			v-if="tree"
			v-model:opened="expanded"
			:selected="active"
			class="mt-2"
			:items="tree"
			:draggable="leastRegular"

		>
			<template #root-button>
				<v-tooltip text="Create root directory" location="end">
					<template #activator="{props}">
						<v-btn
							v-bind="props"
							color="almostBlack"
							@click="createRootDirectory = true"
							icon="mdi-plus"
							size="small"
						/>
					</template>
				</v-tooltip>
			</template>
		</DraggableDirectoryTreeCustom>
	</v-sheet>
</template>

<script>
import CreateDirectory from './CreateDirectory.vue';
import UserRoleMixin from '@/mixins/UserRoleMixin.js';
import DraggableDirectoryTree from "@/components/issues/directory-tree/DraggableDirectoryTree.vue";
import DraggableDirectoryTreeCustom from "@/components/issues/directory-tree/DraggableDirectoryTreeCustom.vue";


export default {
	name: 'IssueDirectories',
	mixins: [
		UserRoleMixin
	],
	components: {
		DraggableDirectoryTreeCustom,
		DraggableDirectoryTree,
		CreateDirectory,
	},
	props: {
		showIssues: {
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			search: null,
			createRootDirectory: false,
			expanded: [],
			getMonitor: true,
			over: null,
			loading: false,
		};
	},
	computed: {
		tree() {
			return this.$store.getters['issues/getDirectories'];
		},
		active() {
			return this.$store.getters['issues/selectedDirectories'];
		},
	},
	async created() {
		const vm = this;
		this.$event.$on(this.$event.types.LOGIN, function () {
			vm.loadTree();
		});
		this.$event.$on(this.$event.types.CREATED_DIRECTORY, function () {
			vm.loadTree();
		});
		this.$event.$on(this.$event.types.DELETED_DIRECTORY, function () {
			vm.loadTree();
		});
		this.$event.$on(this.$event.types.RELOAD_DIRECTORY_TREE, function () {
			vm.loadTree();
		});
		await this.loadTree();
		if(this.active.length > 0) {
			this.expanded = [...this.expanded, ...this.expandTree(this.tree, this.active[0]).filter(id => id !== this.active[0])];
		}
	},
	watch: {
		active: {
			handler() {
				if(this.active.length === 0) {
					return;
				}
				this.expanded = [...this.expanded, ...this.expandTree(this.tree, this.active[0]).filter(id => id !== this.active[0])];
			},
			deep: true
		}
	},
	methods: {
		async loadTree() {
			if (!this.isLogged) {
				return;
			}
			const vm = this;
			this.$emit('loading', true);
			this.loading = true;
			await this.$store.dispatch('issues/loadDirectories');
			this.$emit('loading', false);
			this.loading = false;
		},
		expandTree(tree, id) {
			const vm = this;
			const item = tree.find(item => item.id === id);
			if (item) {
				return [item.id];
			}
			for(const dir of tree) {
				if (dir.child_directories && dir.child_directories.length > 0) {
					const found = vm.expandTree(dir.child_directories, id);
					if (found.length > 0) {
						return [dir.id, ...found];
					}
				}
			}
			return [];
		},
	}
};
</script>
