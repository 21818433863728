export default {
	OPEN_DIALOG: 'OPEN_DIALOG',
	CLOSE_DIALOG: 'CLOSE_DIALOG',
	NOTIFY_MESSAGES: 'NOTIFY_MESSAGES',
	LOGIN: 'LOGIN',
	LOGOUT: 'LOGOUT',
	SHOW_SEARCH: 'SHOW_SEARCH',
	LOADING_APP: 'LOADING_APP',
	COMPLETE_LOADING_APP: 'COMPLETE_LOADING_APP',
	DELETED_DIRECTORY: 'DELETED_DIRECTORY',
	CREATED_DIRECTORY: 'CREATED_DIRECTORY',
	RELOAD_DIRECTORY_TREE: 'RELOAD_DIRECTORY_TREE',
	RELOAD_CURRENT_ISSUE: 'RELOAD_CURRENT_ISSUE',
	ISSUE_ARCHIVED_CHANGE: 'ISSUE_ARCHIVED_CHANGE',
	ISSUE_ACTIVE_CHANGE: 'ISSUE_ACTIVE_CHANGE',
	RELOAD_MY_TASKS: 'RELOAD_MY_TASKS',
	RELOAD_INBOX: 'RELOAD_INBOX',
	RELOAD_MY_WORK: 'RELOAD_MY_WORK',
	DELETED_ISSUE: 'DELETED_ISSUE',
	LOADING_INBOX: 'LOADING_INBOX',
	MOVED_DIRECTORY: 'MOVED_DIRECTORY',
};
