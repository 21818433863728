import IssueApiService from '@/services/issues/IssueApiService';
import {
	ADD_DIRECTORY_CACHE,
	ADD_SOLVER,
	ADD_WATCHER,
	REMOVE_SOLVER,
	REMOVE_WATCHER,
	RESET_CURRENT_ISSUE,
	SET_CURRENT_DIRECTORY,
	SET_CURRENT_ISSUE,
	SET_DIRECTORIES,
	SET_DIRECTORY_AUTHORIZED_USERS,
	SET_SELECTED_DIRECTORIES
} from '@/store/mutations-types';
import EventBus from '@/event-bus/EventBus';

export default {
	/**
	 * Loads data and commits them into store.
	 * @param {*} context
	 */
	loadDirectories(context) {
		return new Promise((resolve, reject) => {
			IssueApiService.getDirectories()
				.then(function (request) {
					context.commit(SET_DIRECTORIES, request.data);
					resolve(request);
				})
				.catch(function (error) {
					console.log(error);
					EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
						{
							type: 'error',
							title: 'Error',
							text: 'Something went wrong.'
						}
					]);
					reject(error);
				});
		});

	},
	getDirectoryEdit(context, {id}) {
		return IssueApiService.getDirectoryEdit(id);
	},
	/**
	 * Loads current category by given ID.
	 * @param {*} context
	 * @param {*} payload
	 */
	loadCurrentDirectory(context, payload) {
		return IssueApiService.getDirectory(payload.id)
			.then(function (request) {
				context.commit(SET_CURRENT_DIRECTORY, request.data);
			})
	},

	createIssue(context, payload) {
		return IssueApiService.createIssue(payload.issue);
	},
	directoryAuthorizedUsers(context, payload) {
		return IssueApiService.getAuthorizedDirectoryUsers(payload.id)
			.then(function (request) {
				context.commit(SET_DIRECTORY_AUTHORIZED_USERS, {users: request.data, id: payload.id});
			})
	},
	/**
	 * Loads current issue by given ID.
	 * @param {*} context
	 * @param {*} payload
	 */
	async loadCurrentIssue(context, payload) {
		try {
			const response = await IssueApiService.getIssue(payload.id);
			context.commit(SET_CURRENT_ISSUE, response.data);
		} catch (error) {
			EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
				{
					type: 'error',
					title: 'Error',
					text: 'Something went wrong.'
				}
			]);
			throw error;
		}
	},

	loadDirectory: (context, payload) => {
		return IssueApiService.getDirectory(payload.id)
			.then(response => {
				context.commit(ADD_DIRECTORY_CACHE, response.data);
				return response.data;
			})
			.catch(error => {
				console.log(error);
				EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
					{
						type: 'error',
						title: 'Error',
						text: 'Something went wrong.'
					}
				]);
			});
	},

	/**
	 * Updates existing issue.
	 * @param context
	 * @param dispatch
	 * @param payload
	 */
	updateIssue(context, payload) {
		return IssueApiService.updateIssue(payload.issue);
	},

	updateCurrentIssue(context, payload) {
		const currentIssue = context.getters.getCurrentIssue;
		if (!currentIssue) {
			throw Error('NOT SELECTED CURRENT ISSUE');
		}
		if (currentIssue.is_archived) {
			EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
				{
					type: 'error',
					title: 'Error',
					text: 'Issue is archived could not modify.'
				}
			]);
			return;
		}
		let issue = {
			...{
				id: currentIssue.id,
				author: currentIssue.author.id,
				status: currentIssue.status,
				issueassignment: currentIssue.issueassignment,
				files: currentIssue.files.map(item => item.id),
				version: currentIssue.version
			},
			...payload
		};
		return new Promise(((resolve, reject) => {
			context
				.dispatch('updateIssue', {issue: issue})
				.then(function () {
					EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
						{
							type: 'success',
							title: 'Success',
							text: 'Issue was updated.'
						}
					]);
					resolve(context.dispatch('loadCurrentIssue', {id: issue.id}));
				})
				.catch(function (error) {
					console.log(error);
					EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
						{
							type: 'error',
							title: 'Error',
							text: 'Something went wrong.'
						}
					]);
					reject(error);
				});
		}))
	},
	updateCurrentDirectory(context, payload) {
		const currentDirectory = context.getters.getCurrentDirectory;
		if (!currentDirectory) {
			throw Error('NOT SELECTED CURRENT ISSUE');
		}
		let directory = {
			...{
				id: currentDirectory.id,
			},
			...payload
		};
		return context
			.dispatch('updateDirectory', {directory: directory})
			.then(function () {
				EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
					{
						type: 'success',
						title: 'Success',
						text: 'Directory was updated.'
					}
				]);
				EventBus.$emit(EventBus.types.RELOAD_DIRECTORY_TREE);
				return Promise.all([
					context.dispatch('loadCurrentDirectory', {id: directory.id}),
				]);
			})
			.catch(function (error) {
				console.log(error);
				EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
					{
						type: 'error',
						title: 'Error',
						text: 'Something went wrong.'
					}
				]);
			});
	},

	/**
	 * Updates existing directory.
	 * @param context
	 * @param dispatch
	 * @param payload
	 */
	updateDirectory({context, dispatch}, payload) {
		return IssueApiService.updateDirectory(payload.directory);
	},

	deleteDirectory(context, payload) {
		return IssueApiService.deleteDirectory(payload.directory.id)
			.then(response => {
				EventBus.$emit(EventBus.types.DELETED_DIRECTORY, payload.directory.id)
				return response;
			});
	},

	uploadFile(context, payload) {
		const currentIssue = context.getters.getCurrentIssue;
		if (!currentIssue) {
			throw Error('NOT SELECTED CURRENT ISSUE');
		}
		const promise = IssueApiService.uploadFile(payload.file);
		promise.then(function (response) {
			let files = currentIssue.files.map(item => item.id);
			const fileResponse = response;
			files.push(response.data.id);

			let issue = {
				files: files
			};
			return context
				.dispatch('updateCurrentIssue', issue)
				.then(function (response) {
					return context
						.dispatch('loadCurrentIssue', {id: id})
						.then(function () {
							new Promise((resolve, reject) => resolve(fileResponse));
						});
				});
		});
		return promise;
	},

	uploadFiles(context, payload) {
		const currentIssue = context.getters.getCurrentIssue;
		if (!currentIssue) {
			throw Error('NOT SELECTED CURRENT ISSUE');
		}
		return context.dispatch('uploadFilesNoReload', payload)
			.then(values => {
				let files = [
					...currentIssue.files.map(item => item.id),
					...values.map(item => item.id)
				];

				let issue = {
					files: files
				};
				if(payload.hasOwnProperty('force') && payload.force) {
					issue.force = true;
				}
				return context
					.dispatch('updateCurrentIssue', issue)
					.then(function (response) {
						return context.dispatch('loadCurrentIssue', {id: currentIssue.id});
					})
					.then(function () {
						return values.map(item => item.file);
					});
			});
	},

	uploadFilesNoReload(context, payload) {
		const promises = [];
		payload.files.forEach(item => {
			promises.push(IssueApiService.uploadFile(item));
		});
		return Promise.all(promises).then(values => {
			return values.map(res => res.data);
		});
	},


	/**
	 * Deletes issue by given ID.
	 * @param context
	 * @param dispatch
	 * @param payload
	 * @returns {*|AxiosPromise<any>}
	 */
	deleteIssue({context, dispatch}, payload) {
		return new Promise((resolve, reject) => {
			IssueApiService.deleteIssue(payload.id)
				.then(response => {
					EventBus.$emit(EventBus.types.DELETED_ISSUE, payload.id);
					resolve(response);
				})
				.catch(error => reject(error));
		});
	},

	resetCurrentIssue(context) {
		context.commit(RESET_CURRENT_ISSUE, null);
	},

	/**
	 * Adds watcher to current issue.
	 * @param {*} context
	 * @param {*} payload
	 */
	addWatcher(context, payload) {
		context.commit(ADD_WATCHER, payload.item);
	},

	/**
	 * Removes watcher in current issue.
	 * @param {*} context
	 * @param {*} payload
	 */
	removeWatcher(context, payload) {
		context.commit(REMOVE_WATCHER, payload.item);
	},

	/**
	 * Adds solver to current issue.
	 * @param {*} context
	 * @param {*} payload
	 */
	addSolver(context, payload) {
		context.commit(ADD_SOLVER, payload.item);
	},

	/**
	 * Removes solver in current issue.
	 * @param {*} context
	 * @param {*} payload
	 */
	removeSolver(context, payload) {
		context.commit(REMOVE_SOLVER, payload.item);
	},
	commentCurrentIssue(context, payload) {
		const user = context.rootState.login.currentUser;
		if (!user) {
			return;
		}
		const def = {
			user: user.id,
			text: '',
			files: [],
			user_annotation: []
		};
		const comment = {...def, ...payload};
		const currentIssue = context.getters.getCurrentIssue;
		if (!currentIssue) {
			throw Error('NOT SELECTED CURRENT ISSUE');
		}
		let promise;
		if (comment.files.length > 0) {
			promise = context.dispatch('uploadFilesNoReload', {files: comment.files})
				.then(files => {
					comment.files = files.map(item => item.id);
					return IssueApiService.commentCurrentIssue(
						currentIssue.id,
						comment
					);
				});
		} else {
			promise = IssueApiService.commentCurrentIssue(
				currentIssue.id,
				comment
			);
		}
		promise.then(function (response) {
			context.dispatch('loadCurrentIssue', {id: currentIssue.id});
		});
		return promise;
	},
	deleteCurrerntIssueFiles(context, payload) {
		const currentIssue = context.getters.getCurrentIssue;
		if (!currentIssue) {
			throw Error('NOT SELECTED CURRENT ISSUE');
		}
		const files = currentIssue.files
			.map(item => item.id)
			.filter(item => payload.files.indexOf(item) < 0);
		return context.dispatch('updateCurrentIssue', {files: files});
	},
	createDirectory(context, payload) {
		return IssueApiService.createDirectory(payload.directory)
			.then(response => {
				EventBus.$emit(EventBus.types.CREATED_DIRECTORY, {id: response.data.id, parent: response.data.parent});
				return response;
			});
	},
	addIssueSubIssue(context, payload) {
		const currentDirectory = payload.issue.directory;
		const subIssue = JSON.parse(JSON.stringify(payload.subIssue));
		if (subIssue.hasOwnProperty('child_issues') && subIssue.child_issues.length > 0) {
			EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
				{
					type: 'error',
					title: 'Error',
					text: 'Could not set set sub issue because has sub issue.'
				}
			]);
			return context.dispatch('loadCurrentDirectory', {id: currentDirectory});
		}
		subIssue.directory = null;
		subIssue.parent_issue = payload.issue.id;
		if (typeof subIssue.author === 'object') {
			subIssue.author = subIssue.author.id;
		}
		subIssue.solvers = subIssue.solvers.map(item => item.id);
		if (subIssue.watchers) {
			subIssue.watchers = subIssue.watchers.map(item => item.id);
		}
		return context.dispatch('updateIssue', {issue: subIssue}).then(function () {
			EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
				{
					type: 'success',
					title: 'Success',
					text: 'Issue successfully moved.'
				}
			]);
			return context.dispatch('loadCurrentDirectory', {id: currentDirectory});
		})
	},
	addDirectorySubIssue(context, payload) {
		const currentDirectory = context.getters.getCurrentDirectory.id;
		const subIssue = JSON.parse(JSON.stringify(payload.subIssue));
		subIssue.directory = payload.directoryId;
		subIssue.parent_issue = null;
		if (typeof subIssue.author === 'object') {
			subIssue.author = subIssue.author.id;
		}
		subIssue.solvers = subIssue.solvers.map(item => item.id);
		if (subIssue.watchers) {
			subIssue.watchers = subIssue.watchers.map(item => item.id);
		}
		return context.dispatch('updateIssue', {issue: subIssue}).then(function () {
			EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
				{
					type: 'success',
					title: 'Success',
					text: 'Issue successfully moved.'
				}
			]);
			return context.dispatch('loadCurrentDirectory', {id: currentDirectory});
		})
	},
	toggleIssueStatus(context, payload) {
		const issue = JSON.parse(JSON.stringify(payload.issue));
		if (typeof issue.author === 'object') {
			issue.author = issue.author.id;
		}
		issue.solvers = issue.solvers.map(item => item.id);
		if (issue.status === 'active') {
			issue.status = 'completed'
		} else {
			issue.status = 'active'
		}
		return context.dispatch('updateIssue', {issue: issue}).then(function () {
			EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
				{
					type: 'success',
					title: 'Success',
					text: 'Issue status changed.'
				}
			]);
			const currentDirectory = context.getters.getCurrentDirectory.id;
			const currentIssue = context.getters.getCurrentIssue.id;
			return Promise.all([
				context.dispatch('loadCurrentDirectory', {id: currentDirectory}),
				context.dispatch('loadCurrentIssue', {id: currentIssue})
			]);
		})
	},
	moveDirectory(context, payload) {
		const promises = payload.directories
			.map(dir => context.dispatch('updateDirectory', {directory: dir}))
		return Promise
			.all(promises)
			.then(() => {
				EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
					{
						type: 'success',
						title: 'Success',
						text: 'Directory moved'
					}
				]);
				EventBus.$emit(EventBus.types.RELOAD_DIRECTORY_TREE);
			})
			.catch(error => {
				console.log(error);
				EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
					{
						type: 'error',
						title: 'Error',
						text: 'Something get wrong. Please try again later.'
					}
				]);
			})
	},
	updateCurrentIssueComment(context, payload) {
		const currentIssue = context.getters.getCurrentIssue;
		if (!currentIssue) {
			throw Error('NOT SELECTED CURRENT ISSUE');
		}
		return IssueApiService.updateComment(currentIssue.id, payload.comment);
	},
	setSelectedDirectories(context, payload) {
		context.commit(SET_SELECTED_DIRECTORIES, payload);
	}
};
