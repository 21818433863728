import {urls} from "@/config/urls";
import axios, {contentTypeJson} from "@/plugins/axios";
import EventBus from '@/event-bus/EventBus';
import userDataStorage from "@/plugins/userDataStorage";

export default {
	/**
	 *
	 * @param {string} username
	 * @param {string} password
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	login(username, password) {
		const postData = {
			username: username,
			password: password
		};

		return axios.post(urls.USERS_LOGIN_LOCAL, postData, contentTypeJson)
			.then(response => this.loginSuccessful(response))
			.catch(error => this.loginFailed(error))
	},
	/**
	 *
	 * @param {string} authToken
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	googleLogin(authToken) {
		return axios.post(urls.USERS_LOGIN_GOOGLE, {code: authToken}, contentTypeJson)
			.then(response => this.loginSuccessful(response, true))
			.catch(error => this.loginFailed(error, true));
	},
	logout() {
		return axios.post(urls.USERS_LOGOUT, {});
	},
	/**
	 *
	 * @param response
	 * @param {boolean} googleLogin
	 * @returns {*}
	 */
	loginSuccessful(response, googleLogin = false) {
		if (!response.data.auth_token) {
			return this.loginFailed(response, googleLogin);
		}

		const user = response.data;
		user.googleLogin = googleLogin;
		userDataStorage.set('authToken', response.data.auth_token)
		return new Promise((resolve) => resolve({user: user, token: response.data.auth_token}));
	},
	/**
	 *
	 * @param error
	 * @param {boolean} googleLogin
	 * @returns {*}
	 */
	loginFailed(error, googleLogin = false) {
		console.log(error);
		const response = error.response;
		const errorMessages = []
		if (googleLogin && response.status === 404) {
			errorMessages.push({
				type: 'error',
				title: 'Error',
				text: 'Google user is not registered in Motion Task'
			});
		} else if (response.status === 400 && response.data.error && response.data.error.non_field_errors) {
			response.data.error.non_field_errors.map(item => {
				return {
					type: 'error',
					title: 'Error ' + item.code,
					text: item.message
				};
			}).forEach(item => errorMessages.push(item));
		} else {
			errorMessages.push({
				type: 'error',
				title: 'Error',
				text: 'Something get wrong'
			})
		}
		if (errorMessages.length > 0) {
			EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, errorMessages);
		}

		return new Promise((resolve, reject) => reject(error));
	},
	getMe() {
		return axios.get(urls.USERS_ME);
	},
}
