export const urls = {
	USERS_LOGIN_LOCAL: 'users/login/local/',
	USERS_LOGIN_GOOGLE: 'users/login/google/',
	USERS_LOGOUT: 'users/logout/',
	USERS: 'users/users/',
	USERS_SEARCH: 'users/search/',
	USERS_INVITATIONS: 'users/invitations',
	USERS_ME: 'users/me/',
	USERS_ME_INBOX: 'users/me/inbox/',
	USERS_ME_NOTIFICATION: "users/me/notification/",
	USERS_ME_INBOX_ONLY_UNREAD: 'users/me/inbox/only-unread',
	USERS_ME_INBOX_UNREAD_COUNT: 'users/me/inbox/unread-count/',
	USERS_ME_MY_TASKS: 'users/me/my-tasks/',
	USERS_ME_MY_WORK: 'users/me/my-work/',
	USERS_RESET_PASSWORD: 'users/request-password-reset/',
	USERS_NEW_PASSWORD: 'users/password-reset/',

	GROUPS: 'users/groups/',
	GROUPS_SEARCH: 'users/groups/search/',

	ISSUES_DIRECTORY: 'issues/directory/',

	ISSUES: 'issues/issues/',
	ISSUES_FILES: 'issues/files/',

	FILES_ISSUE_FILE: 'files/issue_files/',

	SEARCH: 'search/'
};
