<script>
export default {
	name: "UserAvatar",
	props: {
		size: {
			type: Number,
			default: 30
		},
		color: {
			type: String,
			default: "grey"
		},
		user: {
			type: Object,
			default: null
		}
	},
	computed: {
		firstNameChar: function () {
			if(!this.user) {
				return '';
			}
			try {
			return this.user.first_name[0].toUpperCase() + this.user.last_name[0].toUpperCase();
			} catch (e) {
				console.error(e);
				console.log(this.user);
				return '';
			}
		}
	}
}
</script>

<template>
	<v-avatar :color="color" :size="size">
		<span v-if="user" class="white--text body-2">{{ firstNameChar }}</span>
		<v-icon v-else color="white" :size="size / 2">mdi-account-plus</v-icon>
	</v-avatar>
</template>

<style scoped>

</style>
