//Actions for Users
import {
	CLEAR_SEARCH,
	INIT_SEARCH,
	NEXT_SEARCH
} from '@/store/mutations-types';
import SearchApiService from '@/services/search/SearchApiService';

import EventBus from '@/event-bus/EventBus';
/**
 * Vuex store actions for Users module.
 */
export default {
	search(context, payload) {
		if (payload.text === '') {
			context.commit(CLEAR_SEARCH);
			return;
		}

		return SearchApiService.search(payload.text)
			.then(response => {
				context.commit(INIT_SEARCH, {
					data: response.data,
					query: payload.text
				});
				return response.data;
			})
			.catch(error => {
				console.error(error);
				EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
					{
						type: 'error',
						title: 'Error',
						text: 'Something get wrong.'
					}
				]);
			});
	},
	loadNext(context, payload) {
		if (!context.state.loadNext) {
			EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
				{
					type: 'error',
					title: 'Error',
					text: 'Something get wrong.'
				}
			]);
			return;
		}
		return SearchApiService.loadNext(context.state.loadNext)
			.then(response => {
				context.commit(NEXT_SEARCH, {
					data: response.data
				});
				return response.data;
			})
			.catch(error => {
				console.error(error);
				EventBus.$emit(EventBus.types.NOTIFY_MESSAGES, [
					{
						type: 'error',
						title: 'Error',
						text: 'Something get wrong.'
					}
				]);
			});
	}
};
