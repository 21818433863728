<template>
	<v-dialog v-model="innerDialog" persistent max-width="500">
		<v-card>
			<v-overlay
				:absolute="true"
				:value="sending"
			>
				<v-progress-circular indeterminate color="primary"></v-progress-circular>
			</v-overlay>
			<v-card-title class="headline">Create directory</v-card-title>
			<v-card-text>
				<v-text-field
					v-model="name"
					label="Directory name"
					class=""
					ref="input"
					autofocus
					@keydown.esc="close"
					@keyup.enter="create"
				></v-text-field>
			</v-card-text>
			<v-card-actions class="px-4 pb-4">
				<v-btn color="error" text @click="close">Close</v-btn>
				<v-spacer></v-spacer>
				<v-btn :disabled="name.length === 0" class="success" depressed @click="create">Create</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>


export default {
	name: 'CreateDirectory',
	props: {
		directory: {
			type: Object,
			default: null
		},
		modelValue: {
			type: Boolean,
			default: false
		},
	},
	emits: ['sending', 'created', 'update:modelValue'],
	data() {
		return {
			sending: false,
			name: '',
		};
	},
	computed: {
		innerDialog: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit('update:modelValue', val);
			}
		}
	},
	mounted() {
		if (this.$refs.input) {
			this.$refs.input.focus();
		}
	},
	updated() {
		if (this.dialog && this.$refs.input) {
			this.$refs.input.focus();
		}
	},
	watch: {
		dialog(val) {
			if (val) {
				const vm = this;
				setTimeout(() => {
					vm.$refs.input.focus();
				}, 150);
			}
		}
	},
	methods: {
		open() {
			this.innerDialog = true;
		},
		close() {
			this.$refs.input.focus();
			this.name = '';
			this.innerDialog = false;
		},
		create() {
			let vm = this;
			if (this.name === '') {
				this.$event.$emit(this.$event.types.NOTIFY_MESSAGES, [
					{
						type: 'error',
						title: 'Error',
						text: 'Directory name cannot be empty.'
					}
				]);
				return;
			}
			let directory = {
				name: this.name
			};
			if (this.directory) {
				directory.parent = this.directory.id;
			}
			this.sending = true;
			this.$emit('sending', true);
			this.$store
				.dispatch('issues/createDirectory', {
					directory: directory
				})
				.then(function (request) {
					//Reset form
					vm.name = '';
					vm.innerDialog = false;

					vm.$event.$emit(vm.$event.types.NOTIFY_MESSAGES, [
						{
							type: 'success',
							title: 'Success',
							text: 'Directory was created.'
						}
					]);
				})
				.then(function () {
					vm.$emit('created', {parent: directory.parent});
				})
				.catch(function (error) {
					console.log(error);
					vm.$event.$emit(vm.$event.types.NOTIFY_MESSAGES, [
						{
							type: 'error',
							title: 'Error',
							text: 'Something went wrong.'
						}
					]);
				})
				.finally(() => {
					vm.sending = false;
				});
		}
	}
};
</script>
