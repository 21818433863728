<script>
export default {
	name: "DeleteDirectory",
	props: {
		modelValue: {
			type: Boolean,
			default: false
		},
		directory: {
			type: Object,
			required: true
		},
	},
	computed: {
		innerDialog: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit('update:modelValue', val);
			}
		}
	},
	methods: {
		deleteDirectory() {
			const _this = this;
			this.$store
				.dispatch('issues/deleteDirectory', {
					directory: this.directory
				})
				.then(function (request) {
					_this.$event.$emit(_this.$event.types.NOTIFY_MESSAGES, [
						{
							type: 'success',
							title: 'Success',
							text: 'Directory was deleted.'
						}
					]);
				})
				.catch(function (error) {
					console.log(error);
					_this.$event.$emit(_this.$event.types.NOTIFY_MESSAGES, [
						{
							type: 'error',
							title: 'Error',
							text: 'Something went wrong.'
						}
					]);
				})
				.finally(function () {
					_this.innerDialog = false;
				});
		},
	}
}
</script>

<template>
	<v-dialog v-model="innerDialog" width="450px">
		<v-card>
			<v-card-title>
				Do you really want to delete the folder?
			</v-card-title>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					variant="text"
					@click="innerDialog = false"
				>
					No
				</v-btn>

				<v-btn
					variant="text"
					color="green darken-1"
					@click="deleteDirectory"
				>
					Yes
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style scoped>

</style>
