/**
 * Vuex store getters for Users module.
 */
export default {
	issues: function(state) {
		return state.issues;
	},
	directories: function (state) {
		return state.directories;
	}
};
