import * as mt from './mutations-types.js';

export default {

	[mt.SET_GROUPS]: (state, data) => {
		state.groups = data;
	},
	setLanguage(state, lang) {
		localStorage.currentLang = lang;
		state.curLanguage = lang;
	},

	dialogs: (state, dialogs) => {
		state.dialogs = dialogs;
	}
}
