import actions from './actions';
import getters from './getters';
import mutations from './mutations';

/**
 * Vuex store for Groups module.
 * For more see: https://vuex.vuejs.org/guide/modules.html.
 */
const Groups = {
	namespaced: true,
	state: {
		groups: []
	},
	mutations: mutations,
	actions: actions,
	getters: getters
};

export default Groups;
