
import {urls} from '@/config/urls';
import axios from "@/plugins/axios";

/**
 * Service for handling Users module API requests.
 */
export default {
    /**
     * Gets users list from API.
     *
     * @returns {AxiosPromise<any>}
     */
    getUsers: function () {
        return axios.get(
            urls.USERS
        );
    },

    /**
     * Gets single user by given ID from API.
     *
     * @param userId
     * @returns {Promise<AxiosResponse<any>>}
     */
    getUser: function (userId) {
        return axios.get(
            urls.USERS + userId + '/',
        );
    },

    /**
     * Updates user data to API.
     *
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateUser: function (data) {
        return axios.patch(
            urls.USERS + data.id + '/',
            data
        );
    },

    /**
     * Sends new user to API.
     *
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    inviteUser: function (data) {
        return axios.post(
            urls.USERS_INVITATIONS + '/invite/',
            data
        );
    },

    /**
     * Sends data about invited user to API.
     *
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    acceptUserInvitation: function (data) {
        return axios.post(
            urls.USERS_INVITATIONS +
            '/accept/' +
            data.token +
            '/',
            data
        );
    },

    /**
     * Deletes user in API.
     *
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteUser: function (id) {
        return axios.delete(
            urls.USERS + '/' + id + '/'
        );
    },

    /**
     * Searches users by string.
     *
     * @param string
     * @returns {AxiosPromise<any>}
     */
    searchUsers: async function (string) {
        return await axios.get(urls.USERS_SEARCH, {params: {search: string}});
    },

    getMyInbox: function (onlyUnread, offset = 0, limit = 10) {
        const url = onlyUnread ? urls.USERS_ME_INBOX_ONLY_UNREAD : urls.USERS_ME_INBOX;
        return axios.get(url, {params: {limit: limit, offset: offset}});
    },
    getMyInboxUnreadCount: function () {
        return axios.get(urls.USERS_ME_INBOX_UNREAD_COUNT);
    },
    getNotification: function (id) {
        return axios.get(urls.USERS_ME_NOTIFICATION + id + '/');
    },
    toggleNotification: function (id) {
        return axios.put(urls.USERS_ME_INBOX + id + '/');
    },
    readAllIssueNotifications: function (id) {
        return axios.put(urls.USERS_ME_INBOX + id + '/all');
    },
    myTasks: function () {
        return axios.get(urls.USERS_ME_MY_TASKS);
    },
    myWork: function () {
        return axios.get( urls.USERS_ME_MY_WORK);
    },
    userWork: function (id) {
        return axios.get('users/' + id + '/issues/');
    },
    resetPassword: function (email) {
        return axios.post(urls.USERS_RESET_PASSWORD, {email: email});
    },
    newPassword: function (token, password) {
        return axios.post(urls.USERS_NEW_PASSWORD, {
            change_password_token: token,
            new_password: password
        });
    }
};
