<template>
	<v-navigation-drawer
		class="nav overflow-visible "
		color="almostBlack"
		id="side-drawer"
		:width="width"
	>
		<v-overlay :absolute="true" :value="loading">
			<v-progress-circular indeterminate color="primary"></v-progress-circular>
		</v-overlay>
		<IssueDirectories @loading="setLoading"></IssueDirectories>
		<v-avatar
			id="resizer"
			color="primary"
			size="small"
			class="position-absolute"
			icon="mdi-unfold-more-vertical"
			@mousedown="onMouseDown"
		>
		</v-avatar>
	</v-navigation-drawer>
</template>

<script>
import IssueDirectories from "@/components/issues/IssueDirectories.vue";
import {throttle} from "lodash";

export default {
	name: "AppNavigation",
	components: {IssueDirectories},
	props: {
		source: String,
	},
	data() {
		return {
			loading: true,
			width: 280,
			borderSize: 7,
			mouseDown: false
		};
	},

	methods: {
		setLoading(state) {
			this.loading = state;
		},
		onMouseMove: throttle(function (e) {
			console.log("move", e.clientX);
			const width = e.clientX < 100 ? 100 : e.clientX;

			this.$nextTick(function () {
				this.width =  ""+width;
			});
			document.querySelector("#side-drawer").style.width = width + "px";
			document.querySelector(".v-main").style.paddingLeft = width + "px";
		}, 100),
		onMouseDown() {
			console.log("add listener");
			document.addEventListener("mouseup", this.onMouseUp);
			document.addEventListener("mousemove", this.onMouseMove);
		},
		onMouseUp() {
			console.log("remove listener");
			document.removeEventListener("mouseup", this.onMouseUp);
			document.removeEventListener("mousemove", this.onMouseMove);
		},
	},
};

</script>

<style>
#resizer {
	cursor: col-resize;
	position: absolute;
	right: -1.5rem;
	top: 50%;
}
</style>
