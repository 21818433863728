<template>
	<v-app id="inspire">
		<v-layout>
			<AppToolbar/>
			<app-navigation></app-navigation>
			<app-search></app-search>
			<v-main class="almostBlack white--text">
				<keep-alive>
					<slot></slot>
				</keep-alive>
			</v-main>
		</v-layout>
	</v-app>
</template>

<script>
import AppNavigation from '@/components/core/AppNavigation.vue';
import AppToolbar from '@/components/core/AppToolbar.vue';
import AppFooter from '@/components/core/AppFooter.vue';
import AppSearch from '@/components/core/search/AppSearch.vue';

export default {
	name: "Default",
	components: {
		AppNavigation,
		AppToolbar,
		AppFooter,
		AppSearch
	}
}
</script>
