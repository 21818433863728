<template>
	<div>
		<AppNotifications/>
	</div>
</template>

<script lang="js">

import AppNotifications from "@/components/core/search/AppNotifications.vue";

export default {
	name: 'AppDashboard',
	components: {AppNotifications},
};
</script>
